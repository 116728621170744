// gallery.js
import React from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import g1 from '../../assets/images/g1.jpg';
import g2 from '../../assets/images/g2.jpg';
import g3 from '../../assets/images/g3.jpg';
import g4 from '../../assets/images/g4.jpg';
import g5 from '../../assets/images/g5.jpg';
import g6 from '../../assets/images/g6.jpg';
import g7 from '../../assets/images/g7.jpg';
import g8 from '../../assets/images/g8.jpg';
const Gallery = () => {
  return(
    <div>
      <Header />
      {/* Your main content */}
      <main>
      <section id="portfolio" class="portfolio pt-2" >

<div class="container">

  <div class="section-header">
    <h2>Gallery</h2>
    <p>Non hic nulla eum consequatur maxime ut vero memo vero totam officiis pariatur eos dolorum sed fug dolorem
      est possimus esse quae repudiandae. Dolorem id enim officiis sunt deserunt esse soluta consequatur quaerat
    </p>
  </div>

</div>

<div class="container-fluid" data-aos="fade-up" data-aos-delay="200">

  <div class="portfolio-isotope" data-portfolio-filter="*" data-portfolio-layout="masonry"
    data-portfolio-sort="original-order">



    <div class="row g-0 portfolio-container">

      <div class="col-xl-3 col-lg-4 col-md-6 portfolio-item filter-app">
        <img src={g1} class="img-fluid" alt=""/>
        <div class="portfolio-info">
          
          <a href={g1} title="App 1" data-gallery="portfolio-gallery"
            class="glightbox preview-link"><i class="bi bi-zoom-in"></i></a>
        
        </div>
      </div>

      <div class="col-xl-3 col-lg-4 col-md-6 portfolio-item filter-product">
        <img src={g2} class="img-fluid" alt=""/>
        <div class="portfolio-info">
          
          <a href={g2} title="Product 1" data-gallery="portfolio-gallery"
            class="glightbox preview-link"><i class="bi bi-zoom-in"></i></a>
          
        </div>
      </div>

      <div class="col-xl-3 col-lg-4 col-md-6 portfolio-item filter-branding">
        <img src={g3} class="img-fluid" alt=""/>
        <div class="portfolio-info">
          
          <a href={g3} title="Branding 1" data-gallery="portfolio-gallery"
            class="glightbox preview-link"><i class="bi bi-zoom-in"></i></a>
          
        </div>
      </div>

      <div class="col-xl-3 col-lg-4 col-md-6 portfolio-item filter-books">
        <img src={g4} class="img-fluid" alt=""/>
        <div class="portfolio-info">
          
          <a href={g4} title="Branding 1" data-gallery="portfolio-gallery"
            class="glightbox preview-link"><i class="bi bi-zoom-in"></i></a>
          
        </div>
      </div>

      <div class="col-xl-3 col-lg-4 col-md-6 portfolio-item filter-app">
        <img src={g5} class="img-fluid" alt=""/>
        <div class="portfolio-info">
         
          <a href={g5} title="App 2" data-gallery="portfolio-gallery"
            class="glightbox preview-link"><i class="bi bi-zoom-in"></i></a>
         
        </div>
      </div>

      <div class="col-xl-3 col-lg-4 col-md-6 portfolio-item filter-product">
        <img src={g6} class="img-fluid" alt=""/>
        <div class="portfolio-info">
         
          <a href={g6} title="Product 2" data-gallery="portfolio-gallery"
            class="glightbox preview-link"><i class="bi bi-zoom-in"></i></a>
          
        </div>
      </div>

      <div class="col-xl-3 col-lg-4 col-md-6 portfolio-item filter-branding">
        <img src={g7} class="img-fluid" alt=""/>
        <div class="portfolio-info">
         
          <a href={g7} title="Branding 2" data-gallery="portfolio-gallery"
            class="glightbox preview-link"><i class="bi bi-zoom-in"></i></a>
          
        </div>
      </div>

      <div class="col-xl-3 col-lg-4 col-md-6 portfolio-item filter-books">
        <img src={g8} class="img-fluid" alt=""/>
        <div class="portfolio-info">
         
          <a href={g8} title="Branding 2" data-gallery="portfolio-gallery"
            class="glightbox preview-link"><i class="bi bi-zoom-in"></i></a>
         
        </div>
      </div>

     

   

    </div>

  </div>

</div>
</section>
        </main>
        <Footer />
        </div>
     ) 
};

export default Gallery;