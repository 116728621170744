import React, { useState } from 'react';
import logo from '../../assets/images/logo_night.png'
const Header = () => {

  const[scrolly,setscrolly]=useState(false);

 
    document.addEventListener('scroll', () => {
      window.scrollY > 50 ? setscrolly(true):setscrolly(false) 
    });
    const [toggle, setToggle] = useState(true);
    const handleClick = () => {
      setToggle(!toggle)
      document.body.classList.toggle("mobile-nav-active");
    };
   
   
      
    

  return (
    
   <header id="header" className={ scrolly? "header fixed-top  sticked" :" header fixed-top "}  data-scrollto-offset="0">
    <div className="two-menu container-fluid d-flex align-items-center justify-content-center">

      <a href="index.html" className="logo d-flex align-items-center scrollto me-auto me-lg-0">

        <img style={{width: 100}}  alt='' src={logo} />
      </a>

      <nav id="navbar" className="navbar">
        <ul>

          <li><a href="/">Home </a>

          </li>

          <li><a className="nav-link " href="/about">About</a></li>
          <li><a className="nav-link " href="/gallery">Gallery</a></li>
          <li className="desk-logo"><a href="/"><img style={{width: 100}} alt='' src={logo}/></a>
          </li>
          <li><a className="nav-link " href="/faq">FAQ</a></li>



          <li><a className="nav-link " href="/contact">Contact</a></li>
        </ul>
        <i className={ toggle ? 'bi bi-list mobile-nav-toggle d-none' : 'bi bi-x mobile-nav-toggle d-none' } onClick={handleClick} ></i>
      </nav>

      <a className="btn-getstarted scrollto" href="index.html#about">Contribute</a>

    </div>
  </header>
  );
};

export default Header;