import '../App.css';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Switch, Route } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Faq from './Pages/Faq';
import Gallery from './Pages/Gallery';
import NotFound from './Pages/NotFound';
import shipping from './Pages/Shipping';

function App() {
  return (
    <Router>
    <Switch>
      {/* Define your routes */}
      <Route exact path="/" component={Home} />
      <Route path="/about" component={About} />
      <Route path="/contact" component={Contact} />
      <Route path="/faq" component={Faq} />
      <Route path="/gallery" component={Gallery} />
      <Route path="/shipping" component={shipping} />

      {/* A catch-all route for 404 page */}
      <Route component={NotFound} />
    </Switch>
  </Router>
  );
}

export default App;
