// NotFound.js
import React from 'react';
import logo from '../../assets/images/logo_night.png'
const NotFound = () => {
  return(
    <div class="d-flex align-items-center justify-content-center vh-100 notfound">
    <div class="text-center">
    <img class="w-25" alt='' src={logo}/>
        <h1 class="display-1 fw-bold">404</h1>
        <p class="fs-3"> <span class="text-danger">Opps!</span> Page not found.</p>
        <p class="lead">
            The page you’re looking for doesn’t exist.
          </p>
        <a href="index.html" class="contribute-btn">Go Home</a>
    </div>
</div>
  )
};

export default NotFound;