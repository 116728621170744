import React from 'react';
import  { useState } from 'react';
import logo from '../../assets/images/logo_night.png'
const Footer = () => {
  const [loading,setloading]=useState(true);
  
    window.addEventListener('load', () => {
      setloading(false) ;
    });
  
  return (
    
    <footer id="footer" className="footer">

    <div className="footer-content">
      <div className="container">
        <div className="row">

          <div className="col-lg-3 col-md-6">
            <div className="footer-info">
              <p><img style={{width: 100,marginBottom:15}} alt='' src={logo}/></p>

              <p>
                A108 Adam Street <br/>
                NY 535022, USA<br/><br/>
                <strong>Phone:</strong> +1 5589 55488 55<br/>
                <strong>Email:</strong> info@example.com<br/>
              </p>
            </div>
          </div>

          <div className="col-lg-2 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><i className="bi bi-chevron-right"></i> <a href="/">Home</a></li>
              <li><i className="bi bi-chevron-right"></i> <a href="/about">About us</a></li>
              <li><i className="bi bi-chevron-right"></i> <a href="/gallery">Gallery</a></li>
              <li><i className="bi bi-chevron-right"></i> <a href="/faq">Faq</a></li>
              <li><i className="bi bi-chevron-right"></i> <a href="/contact">Contact us</a></li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-6 footer-links">
            <h4>Other Links</h4>
            <ul>

              <li><i className="bi bi-chevron-right"></i> <a href="#">Terms of service</a></li>
              <li><i className="bi bi-chevron-right"></i> <a href="#">Privacy policy</a></li>
            </ul>
          </div>

          <div className="col-lg-4 col-md-6 footer-newsletter">
            <h4>Our Newsletter</h4>
            <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
            <form action="" method="post">
              <input type="email" name="email"/><input type="submit" value="Subscribe"/>
            </form>

          </div>

        </div>
      </div>
    </div>

    <div className="footer-legal text-center">
      <div
        className="container d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-center">

        <div className="d-flex flex-column align-items-center align-items-lg-start">
          <div className="copyright">
            &copy; Copyright <strong><span>NightBirde Foundation</span></strong>. All Rights Reserved
          </div>

        </div>

        <div className="social-links order-first order-lg-last mb-3 mb-lg-0">
          <a href="#" className="twitter"><i className="bi bi-twitter"></i></a>
          <a href="#" className="facebook"><i className="bi bi-facebook"></i></a>
          <a href="#" className="instagram"><i className="bi bi-instagram"></i></a>
          <a href="#" className="google-plus"><i className="bi bi-skype"></i></a>
          <a href="#" className="linkedin"><i className="bi bi-linkedin"></i></a>
        </div>

      </div>
    </div>
     {/* <div id={loading?'preloader':''}></div> */}

  </footer>

  );
};

export default Footer;
