// Home.js
import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import img1 from '../../assets/images/img1.jpg';
import img2 from '../../assets/images/img2.jpg';
import img3 from '../../assets/images/img3.jpg';
import bottle from '../../assets/images/bottle.jpg';
import blog1 from '../../assets/images/blog-1.jpg';
import cloth from '../../assets/images/cloth.png';
import food from '../../assets/images/food.png';
import water from '../../assets/images/water.png';
import home from '../../assets/images/home.png';
const Home = () => {
   return (
        <div>
          <Header />
          {/* Your main content */}
          <main>
          <Carousel data-bs-theme="light" id="hero" className='hero'>
      <Carousel.Item>
      <img src={img1} alt="" className="img-fluid img"/>
        <Carousel.Caption>
        <h2>Welcome</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
            ex ea commodo consequat.</p>
          <a href="/" className="btn-get-started scrollto ">Get Started</a>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img src={img2} alt="" className="img-fluid img"/>
        <Carousel.Caption>
        <h2>Welcome</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
            ex ea commodo consequat.</p>
          <a href="/" className="btn-get-started scrollto ">Get Started</a>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img src={img3} alt="" className="img-fluid img"/>
        <Carousel.Caption>
        <h2>Welcome</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
            ex ea commodo consequat.</p>
          <a href="/" className="btn-get-started scrollto ">Get Started</a>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    <section id="recent-blog-posts" class="recent-blog-posts">

<div class="container " >

  <div class="section-header">
    <h2>Celebrate your Special Day with the kids and make your celebration more joyous</h2>
    <p>We Ensure 100% Transparency With Your Contribution</p>
  </div>

  <div class="row">



    <div class="col-lg-8 " >
      <div class="post-box">
        <div class="post-img"><img src={blog1} class="img-fluid" alt=""/></div>
        <div class="meta">
          <span class="post-date">Fri, September 05</span>
          <span class="post-author"> / Mario Douglas</span>
        </div>
        <h3 class="post-title">We Can Change Everything Together</h3>
        <p>Voluptatem nesciunt omnis libero autem tempora enim ut ipsam id. Odit quia ab eum assumenda. Quisquam
          omnis aliquid necessitatibus tempora consectetur doloribus...</p>
        <a href="blog-details.html" class="readmore stretched-link"><span>Read More</span><i
            class="bi bi-arrow-right"></i></a>
      </div>
    </div>

    <div class="col-lg-4 ">
      <div class="post-box">
        <div class="post-img"><img src={bottle} class="img-fluid" alt=""/></div>
        <div class="meta">
          <span class="post-date">Tue, July 27</span>
          <span class="post-author"> - Free</span>
        </div>
        <h3 class="post-title">Water Bottle</h3>
        <p>Quia nam eaque omnis explicabo similique eum quaerat similique laboriosam. Quis omnis repellat sed quae
          consectetur magnam veritatis dicta nihil...</p>
        <a href="/shipping" class="contribute-btn mt-3 text-center">Contribute</a>
      </div>
    </div>

  </div>

</div>

</section>
<section id="onfocus" class="onfocus">
      <div class="container-fluid p-0" >

        <div class="row g-0">
          <div class="col-lg-6 video-play position-relative">
           
          </div>
          <div class="col-lg-6">
            <div class="content d-flex flex-column justify-content-center h-100">
              <h3>Voluptatem dignissimos provident quasi corporis</h3>
              <p class="fst-italic">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore
                magna aliqua.
              </p>
              <ul>
                <li><i class="bi bi-check-circle"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
                <li><i class="bi bi-check-circle"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
                <li><i class="bi bi-check-circle"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                  aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla
                  pariatur.</li>
              </ul>
              <a href="#" class="read-more align-self-start"><span>Read More</span><i class="bi bi-arrow-right"></i></a>
            </div>
          </div>
        </div>

      </div>
    </section>
    <section id="featured-services" class="featured-services">
    <div class="section-header">
    <h2>Our Services</h2>
    <p>We Ensure 100% Transparency With Your Contribution</p>
  </div>
      <div className="container">

        <div className="row gy-4">

          <div className="col-xl-3 col-md-6 d-flex" >
            <div className="service-item position-relative">
            <img src={home} className='img-fluid mx-auto d-table' alt=''/>
              <h4><a href="" class="stretched-link">We gave Education</a></h4>
              <p>Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi</p>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 d-flex" >
            <div className="service-item position-relative">
            <img src={water} className='img-fluid mx-auto d-table' alt=''/>
              <h4><a href="" class="stretched-link">We gave Pure Water</a></h4>
              <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</p>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 d-flex" >
            <div className="service-item position-relative">
            <img src={food} className='img-fluid mx-auto d-table' alt=''/>
              <h4><a href="" className="stretched-link">We gave Healthy Food</a></h4>
              <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia</p>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 d-flex" >
            <div className="service-item position-relative">
              <img src={cloth} className='img-fluid mx-auto d-table' alt=''/>
              <h4><a href="" className="stretched-link">We gave Good Cloth</a></h4>
              <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis</p>
            </div>
          </div>

        </div>

      </div>
    </section>
          </main>
          <Footer />
        </div>
      );
};

export default Home;